import * as dateFns from 'date-fns'
import { enGB } from 'date-fns/locale'
import { Form, Formik, FormikProps } from 'formik'
import { debounce } from 'lodash'
import React, { Ref, useCallback } from 'react'
import * as yup from 'yup'
import {
  DocumentDateType,
  DocumentStatus,
  UpdateDocumentInput,
  useUpdateDocumentMutation
} from '../../graphql/generated/schema'
import {
  DocumentInformationFormValues,
  FieldOption,
  FieldTheme
} from '../../types'
import {
  addLeadingZeroToDigit,
  formatDateStringWithLeadingZeroDigits
} from '../../utils/dateHelper'
import { attachFocusToFirstFormElementError } from '../../utils/formikHelper'
import { AutocompleteField } from '../AutocompleteField/AutocompleteField'
import { DateField } from '../DateField'
import { INITIAL_DECISION_TYPE } from '../DocumentInformationPanel/DocumentInformationPanel'
import { RadioField } from '../RadioField'
import { SelectField } from '../SelectField'
import styles from './DocumentInformationForm.module.scss'
import { validateField } from './utils/validateField'

export interface DocumentInformationFormProps {
  /** Id of document. */
  documentId: string
  /** Name of document. */
  documentName: string | null
  /** Date type of document, defaults to Dated. */
  documentDateType: DocumentDateType | null
  /** Date of document. */
  documentDate: string | null
  /** Decision type of document. */
  documentDecisionType: FieldOption | null
  /** Related document names for document. This will populate the autocomplete field. */
  relatedDocumentNames: string[] | null
  /** Decision types for document. */
  documentDecisionTypes: FieldOption[] | null
  /** Reference to form, used to handle submission and form value reset. */
  documentStatus: DocumentStatus
  /** Date type of document, defaults to Dated. */
  formRef?: Ref<FormikProps<DocumentInformationFormValues>>
}

const CURRENT_DATE = new Date()
const PAST_DATE = new Date(69, 0)
const MINIMUM_DATE = '01/01/1969'

/** Form containing information about a document such as its name, date and list of decision types.*/
export const DocumentInformationForm: React.FunctionComponent<
  DocumentInformationFormProps
> = ({
  documentId,
  documentName,
  documentDateType,
  documentDate,
  documentDecisionType,
  documentDecisionTypes,
  relatedDocumentNames,
  documentStatus,
  formRef
}) => {
  const [updateDocumentMutation] = useUpdateDocumentMutation()

  const initialValues: DocumentInformationFormValues = {
    documentName: documentName ? documentName : '',
    documentDateType: documentDateType
      ? documentDateType
      : DocumentDateType.Dated,
    documentDecisionType: documentDecisionType
      ? documentDecisionType.value
      : '',
    documentDate: documentDate ? documentDate : '--'
  }

  // Parses date from string in format 'yyyy-mm-dd'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseDateString = (value: any, originalValue: any) => {
    const parsedDate: Date = dateFns.isDate(originalValue)
      ? originalValue
      : dateFns.parse(originalValue, 'yyyy-MM-dd', new Date(), {
          locale: enGB
        })

    return parsedDate
  }

  const validationSchema = yup.object({
    documentName: yup
      .string()
      .required('Enter a document name')
      .typeError('Enter a document name'),
    documentDateType: yup.string().required('Choose a document date'),
    documentDate: yup.mixed().when('documentDateType', {
      is: (documentDateType: string) =>
        documentDateType === DocumentDateType.Dated,
      then: yup
        .date()
        .transform(parseDateString)
        .min(PAST_DATE, `Date must be later than ${MINIMUM_DATE}`)
        .max(CURRENT_DATE, 'Date must be in the past')
        .typeError('Must be a valid date')
    })
  })

  const validationSchemaWithDecisionTypes = yup.object({
    documentName: yup
      .string()
      .required('Enter a document name')
      .typeError('Enter a document name'),
    documentDateType: yup.string().required('Choose a document date'),
    documentDate: yup.mixed().when('documentDateType', {
      is: (documentDateType: string) =>
        documentDateType === DocumentDateType.Dated,
      then: yup
        .date()
        .transform(parseDateString)
        .min(PAST_DATE, `Date must be later than ${MINIMUM_DATE}`)
        .max(CURRENT_DATE, 'Date must be in the past')
        .typeError('Must be a valid date')
    }),
    documentDecisionType: yup
      .string()
      .notOneOf([INITIAL_DECISION_TYPE], 'Choose decision type')
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDocumentMutationDebounced = useCallback(
    debounce(updateDocumentMutation, 1000),
    []
  )

  const updateDocumentInformation = (
    inputValues: UpdateDocumentInput,
    error: string | null
  ) => {
    if (error) {
      return
    }

    const input =
      documentStatus === DocumentStatus.Reviewed
        ? { ...inputValues, status: DocumentStatus.InProgress }
        : inputValues

    updateDocumentMutationDebounced({
      variables: {
        input
      }
    })
  }

  return (
    <Formik
      innerRef={formRef}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={
        documentDecisionTypes
          ? validationSchemaWithDecisionTypes
          : validationSchema
      }
      onSubmit={() => {
        return
      }}
    >
      {({ values, setFieldError, setFieldTouched, isValidating, errors }) => {
        // If validation errors exist, set focus on first error element
        if (!isValidating && errors) {
          attachFocusToFirstFormElementError(errors)
        }
        return (
          <Form className={styles.form}>
            <AutocompleteField
              name="documentName"
              fieldTheme={FieldTheme.GREY}
              label="Document name"
              options={
                relatedDocumentNames
                  ? relatedDocumentNames.map((suggestedDocumentName) => {
                      return {
                        value: suggestedDocumentName,
                        label: suggestedDocumentName
                      }
                    })
                  : undefined
              }
              onChange={async (val) => {
                const { value, error } = await validateField(
                  validationSchema,
                  val,
                  'documentName',
                  setFieldTouched,
                  setFieldError
                )
                updateDocumentInformation(
                  {
                    id: documentId,
                    name: value as string
                  },
                  error
                )
              }}
            />
            <div>
              <RadioField
                name="documentDateType"
                label="Document date"
                radioOptions={[
                  {
                    label: 'Dated',
                    value: DocumentDateType.Dated
                  },
                  {
                    label: 'Undated',
                    value: DocumentDateType.Undated
                  },
                  {
                    label: 'Various',
                    value: DocumentDateType.MultipleDates
                  }
                ]}
                onChange={async (e) => {
                  {
                    const { value, error } = await validateField(
                      validationSchema,
                      e.currentTarget.value,
                      'documentDateType',
                      setFieldTouched,
                      setFieldError
                    )
                    updateDocumentInformation(
                      {
                        id: documentId,
                        dateType: value as DocumentDateType,
                        date:
                          value === DocumentDateType.Dated &&
                          values.documentDate !== '--'
                            ? values.documentDate
                            : null
                      },
                      error
                    )
                  }
                }}
              />
            </div>
            {values.documentDateType === DocumentDateType.Dated && (
              <div>
                <DateField
                  dateName="documentDate"
                  fieldTheme={FieldTheme.GREY}
                  onBlur={async (e) => {
                    if (e.target.value) {
                      e.target.value = addLeadingZeroToDigit(e.target.value)
                      values.documentDate =
                        formatDateStringWithLeadingZeroDigits(
                          values.documentDate,
                          '-'
                        )
                      const { value, error } = await validateField(
                        yup.object({
                          documentDate: yup
                            .date()
                            .transform(parseDateString)
                            .min(
                              PAST_DATE,
                              `Date must be later than ${MINIMUM_DATE}`
                            )
                            .max(CURRENT_DATE, 'Date must be in the past')
                            .typeError('Must be a valid date')
                        }),
                        values.documentDate,
                        'documentDate',
                        setFieldTouched,
                        setFieldError
                      )
                      updateDocumentInformation(
                        {
                          id: documentId,
                          dateType: DocumentDateType.Dated,
                          date: value as string
                        },
                        error
                      )
                    }
                  }}
                />
              </div>
            )}
            {documentDecisionTypes && (
              <SelectField
                name="documentDecisionType"
                label="Decision type"
                options={documentDecisionTypes}
                fieldTheme={FieldTheme.GREY}
                onBlur={async (e) => {
                  const { value, error } = await validateField(
                    validationSchemaWithDecisionTypes,
                    e.currentTarget.value,
                    'documentDecisionType',
                    setFieldTouched,
                    setFieldError
                  )
                  updateDocumentInformation(
                    {
                      id: documentId,
                      decisionTypeId: value as string
                    },
                    error
                  )
                }}
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
