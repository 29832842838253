import { Form, Formik } from 'formik'
import { Button, Heading } from 'govuk-react'
import React, { useEffect, useState } from 'react'
import { KeyEventDateType } from '../../graphql/generated/schema'
import { FieldTheme, KeyEventsModalFormData } from '../../types'
import {
  addLeadingZeroToDigit,
  formatDateStringWithLeadingZeroDigits
} from '../../utils/dateHelper'
import { AutocompleteField } from '../AutocompleteField'
import { DateField } from '../DateField'
import { ModalDialogue } from '../ModalDialogue'
import { RadioField } from '../RadioField'
import { TextAreaField } from '../TextAreaField'
import styles from './KeyEventModal.module.scss'
import {
  getFormValues,
  getPageOptions,
  getPageOptionsTo,
  setFormValuesToSubmit
} from './utils/helper'
import { schema } from './validation/schema'

export interface KeyEventModalProps {
  /** Flag to set modal open. */
  isModalOpen: boolean
  /** Handle modal close actions */
  handleCloseModal: () => void
  /** Handle cancel button click */
  handleCancelClick: () => void
  /** Handle add Key Event button click */
  handleKeyEventUpsert: (data: KeyEventsModalFormData) => void

  pageCount: number

  initialFormValues: KeyEventsModalFormData
}

/** KeyEventsModal description.*/
export const KeyEventModal: React.FunctionComponent<KeyEventModalProps> = ({
  isModalOpen,
  handleCloseModal,
  handleCancelClick,
  handleKeyEventUpsert,
  initialFormValues,
  pageCount
}) => {
  const handleSubmit = async (values: KeyEventsModalFormData) => {
    handleKeyEventUpsert(values)
  }

  const [pageFrom, setPageFrom] = useState(1)

  const [pageFromOptions, setPageFromOptions] = useState<
    { value: string; label: string }[]
  >([])

  const [pageToOptions, setPageToOptions] = useState<
    { value: string; label: string }[]
  >([])

  useEffect(() => {
    setPageFromOptions(getPageOptions(pageCount))
    setPageToOptions(getPageOptionsTo(pageCount, pageFrom))
  }, [pageFrom, pageCount])

  return (
    <ModalDialogue
      title={'Key event'}
      isOpen={isModalOpen}
      onCloseModal={handleCloseModal}
    >
      <div role={'region'} aria-labelledby={'modal-content'}>
        <div id={'modal-content'} className={styles.modalContent}>
          <Formik
            initialValues={getFormValues(initialFormValues)}
            onSubmit={(values) => {
              handleSubmit(setFormValuesToSubmit(values))
            }}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ values, setFieldValue }) => {
              if (
                values.pageFrom !== undefined &&
                values.pageTo === undefined
              ) {
                setPageFrom(Number(values.pageFrom))
              }

              return (
                <div>
                  <Form className={styles.form}>
                    <div>
                      <RadioField
                        name="dateType"
                        label="Key event date"
                        radioOptions={[
                          {
                            label: 'Dated',
                            value: KeyEventDateType.Dated
                          },
                          {
                            label: 'Undated',
                            value: KeyEventDateType.Undated
                          },
                          {
                            label: 'Various',
                            value: KeyEventDateType.MultipleDates
                          }
                        ]}
                        onChange={async (event) => {
                          console.log(initialFormValues.occuredAt)
                          if (event.target.value === KeyEventDateType.Dated) {
                            values.occuredAt =
                              initialFormValues.occuredAt ?? '--'
                          } else {
                            values.occuredAt = null
                          }
                          console.log(initialFormValues.occuredAt)
                        }}
                      />
                    </div>
                    {values.dateType === KeyEventDateType.Dated && (
                      <DateField
                        dateName="occuredAt"
                        fieldTheme={FieldTheme.WHITE}
                        onBlur={async (e) => {
                          if (e.target.value) {
                            e.target.value = addLeadingZeroToDigit(
                              e.target.value
                            )
                            console.log(initialFormValues.occuredAt)
                            values.occuredAt = values.occuredAt
                              ? formatDateStringWithLeadingZeroDigits(
                                  values.occuredAt,
                                  '-'
                                )
                              : values.occuredAt
                            console.log(initialFormValues.occuredAt)
                          }
                        }}
                      />
                    )}
                    <div className={styles.textField}>
                      <TextAreaField
                        name="body"
                        label="Details"
                        hint=""
                      ></TextAreaField>
                    </div>

                    <br></br>

                    <Heading as="h2" size="SMALL">
                      Pages
                    </Heading>

                    <React.Fragment>
                      <div className={styles.tagsContainer}>
                        <div className={styles.pagesContainer} key={'tagKey'}>
                          <AutocompleteField
                            isCreatableSelect={false}
                            label="From"
                            name="pageFrom"
                            options={pageFromOptions}
                            placeholderText={'Select'}
                            onChange={(value) => {
                              setPageFrom(Number(value))
                              setFieldValue('pageTo', value)
                            }}
                          />
                        </div>

                        <div className={styles.pagesContainer}>
                          <AutocompleteField
                            label="To"
                            name="pageTo"
                            options={values.pageFrom ? pageToOptions : []}
                            placeholderText={'Select'}
                            isCreatableSelect={false}
                          />
                        </div>
                      </div>
                    </React.Fragment>

                    <div className={styles.popUpButtonDiv}>
                      <Button margin={2} type="submit">
                        {values.id === '' ? 'Add event' : 'Save event'}
                      </Button>

                      <Button
                        margin={2}
                        buttonShadowColour="#A9A9A9"
                        buttonTextColour="#0b0c0c"
                        buttonHoverColour="#ffdd00"
                        buttonColour="#f3f2f1"
                        onClick={handleCancelClick}
                        name="Cancel"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </ModalDialogue>
  )
}
