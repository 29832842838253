import * as dateFns from 'date-fns'
import { enGB } from 'date-fns/locale'
import { Form, Formik, FormikHelpers } from 'formik'
import { BackLink, Button, Heading, Paragraph, Spinner } from 'govuk-react'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import {
  DrsDocumentIdentifierType,
  DrsSearchType,
  useValidateDrsSearchLazyQuery
} from '../../graphql/generated/schema'
import { CreateBundleFormData } from '../../types'
import {
  addLeadingZeroToDigit,
  formatDateStringWithLeadingZeroDigits
} from '../../utils/dateHelper'
import { attachFocusToFirstFormElementError } from '../../utils/formikHelper'
import { DateField } from '../DateField'
import { ErrorSummaryRetry } from '../ErrorSummaryRetry'

const CURRENT_DATE = new Date()
const PAST_DATE = new Date(69, 0)
const MINIMUM_DATE = '01/01/1900'

const formErrorMessages = {
  unknownSearchIdentifier:
    'The search identifier you have provided does not exist in DRS',
  drsUnavailable: 'DRS Search Service is unavailable',
  noDocuments: 'There are no valid documents in DRS for this search identifier',
  requiredDate: 'Date cannot be blank',
  invalidDate: 'Must be a valid date'
}

export interface DrsDateRangeSearchFormProps {
  /** Initial form data. */
  data: CreateBundleFormData
  /** Function to invoke when going back to the previous step in the form. */
  prev: (data: CreateBundleFormData) => void
  /** Function to invoke when submitting the form. */
  next: (data: CreateBundleFormData, final?: boolean, current?: number) => void
  /** Key if part of multi-step form */
  key?: number
  /** Flag to toggle loading state. */
  isSubmitLoading?: boolean
}

/** Formik form allowing user to enter a Date range value for the DRS Search.
 * This data will then be used download documents from DRS.
 */
export const DrsDateRangeSearchForm: React.FunctionComponent<
  DrsDateRangeSearchFormProps
> = ({ data, prev, next, isSubmitLoading = false }) => {
  const [
    validateDrsSearchQuery,
    { error: validateDrsSearchError, loading: validateDrsSearchLoading }
  ] = useValidateDrsSearchLazyQuery()

  const validateDrsSearchValue = async (
    createError: (params?: yup.CreateErrorOptions) => yup.ValidationError,
    path: string,
    fromDate: string,
    toDate: string
  ) => {
    const {
      data: validateDrsSearchData,
      error: validateDrsSearchValidationError
    } = await validateDrsSearchQuery({
      variables: {
        validateDrsSearchInput: {
          businessAreaId: data.businessArea,
          appealTypeId: data.appealType ? data.appealType : null,
          drsFromDate: fromDate,
          drsToDate: toDate,
          drsDocumentIdentifierValue:
            data.nationalInsuranceNumber ||
            data.claimReferenceNumber ||
            data.claimReferenceNumber,
          drsDocumentIdentifierType:
            DrsDocumentIdentifierType.ClaimReferenceNumber ||
            DrsDocumentIdentifierType.CustomerReferenceNumber ||
            DrsDocumentIdentifierType.Nino
        }
      }
    })

    if (validateDrsSearchData && !validateDrsSearchValidationError) {
      const docCount = validateDrsSearchData.validateDrsSearch?.documentCount

      if (docCount === 0) {
        return createError({
          path,
          message: formErrorMessages.noDocuments
        })
      } else {
        return true
      }
    } else if (validateDrsSearchValidationError) {
      const errorCode =
        validateDrsSearchValidationError.graphQLErrors[0].extensions.code

      if (errorCode === 'DRS_DOCUMENT_IDENTIFIER_NOT_FOUND') {
        return createError({
          path,
          message: formErrorMessages.unknownSearchIdentifier
        })
      } else if (errorCode === 'DRS_SERVER_ERROR') {
        return createError({
          path,
          message: formErrorMessages.drsUnavailable
        })
      } else {
        return true
      }
    } else {
      return false
    }
  }

  // Parses date from string in format 'yyyy-mm-dd'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseDateString = (value: any, originalValue: any) => {
    const parsedDate: Date = dateFns.isDate(originalValue)
      ? originalValue
      : dateFns.parse(originalValue, 'yyyy-MM-dd', new Date(), {
          locale: enGB
        })

    return parsedDate
  }

  const validationSchema = yup.object({
    drsFromDate: yup
      .date()
      .required(formErrorMessages.requiredDate)
      .transform(parseDateString)
      .min(PAST_DATE, `Date must be later than ${MINIMUM_DATE}`)
      .max(CURRENT_DATE, 'Date must be in the past')
      .typeError('Must be a valid date')
      .test(
        'validateDrsSearchIdentifier',
        async (value, { createError, path }) => {
          return validateDrsSearchValue(
            createError,
            path,
            DrsDocumentIdentifierType.ClaimReferenceNumber ||
              DrsDocumentIdentifierType.CustomerReferenceNumber ||
              DrsDocumentIdentifierType.Nino,
            DrsSearchType.DateRange
          )
        }
      ),

    drsToDate: yup
      .date()
      .required(formErrorMessages.requiredDate)
      .transform(parseDateString)
      .min(yup.ref('drsFromDate'), 'End date cannot be before the Start Date')
      .max(CURRENT_DATE, 'Date must be in the past')
      .typeError('Must be a valid date')
      .test(
        'validateDrsSearchIdentifier',
        async (value, { createError, path }) => {
          return validateDrsSearchValue(
            createError,
            path,
            DrsDocumentIdentifierType.ClaimReferenceNumber ||
              DrsDocumentIdentifierType.CustomerReferenceNumber ||
              DrsDocumentIdentifierType.Nino,
            DrsSearchType.DateRange
          )
        }
      ),

    drsSearch: yup.string().when(['drsFromDate', 'drsToDate'], {
      is: (drsFromDate: string | null, drsToDate: string | null) =>
        !!drsFromDate && !!drsToDate,
      then: yup.string()
    })
  })

  const handleSubmit = async (
    values: CreateBundleFormData,
    { setSubmitting }: FormikHelpers<CreateBundleFormData>
  ) => {
    setSubmitting(false)

    let isFinalFormStep = false

    if (values.localUploadRequired === 'No') {
      isFinalFormStep = true
    }

    next(values, isFinalFormStep)
  }

  // Reset focus for accessibility screen readers
  useEffect(() => {
    document.getElementById('main-focus')?.focus()
  }, [])

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        //setFieldValue,
        //setFieldError,
        isSubmitting,
        isValidating,
        errors
      }) => {
        if (isSubmitting && !isValidating && errors) {
          // If validation errors exist, set focus on first error element
          attachFocusToFirstFormElementError(errors)
        }
        return (
          <div>
            <div>
              <BackLink
                href={''}
                type="button"
                onClick={(e) => {
                  // Prevent reload of create bundle route
                  e.preventDefault()
                  prev(values)
                }}
              >
                Back
              </BackLink>
            </div>

            <Heading size="XLARGE">Search for documents by date range</Heading>
            {validateDrsSearchError &&
              validateDrsSearchError.message ===
                'Search Service unavailable' && (
                <>
                  <ErrorSummaryRetry
                    heading="There is a problem retrieving information from the service"
                    includeBorder={true}
                  />
                </>
              )}

            <Paragraph>
              The dates you enter here will return documents within this range.
              Please check the dates to ensure all relevant evidence for the
              appeal has been selected.
            </Paragraph>

            <Form name="date-range-form">
              <div>
                <DateField
                  dateName="drsFromDate"
                  label=""
                  hint="Include DRS documents starting from"
                  onBlur={async (e) => {
                    if (e.target.value) {
                      e.target.value = addLeadingZeroToDigit(
                        e.target.value || ''
                      )
                      values.drsFromDate =
                        formatDateStringWithLeadingZeroDigits(
                          values.drsFromDate || '',
                          '-'
                        )
                    }
                  }}
                />
              </div>

              <div>
                <DateField
                  dateName="drsToDate"
                  label=""
                  hint="Up to"
                  onBlur={async (e) => {
                    if (e.target.value) {
                      e.target.value = addLeadingZeroToDigit(
                        e.target.value || ''
                      )
                      values.drsFromDate =
                        formatDateStringWithLeadingZeroDigits(
                          values.drsFromDate || '',
                          '-'
                        )
                    }
                  }}
                />
              </div>

              <div>
                <Button
                  disabled={isSubmitLoading || validateDrsSearchLoading}
                  type="submit"
                >
                  Continue
                </Button>
                {isSubmitLoading || validateDrsSearchLoading ? (
                  <Spinner className="" width="25px" height="25px" />
                ) : null}
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}
